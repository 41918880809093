<template>
    <div>任务规则配置</div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>

</style>